import './styles.scss'
import { Link } from 'react-router-dom'

const FeatureDetail = ({
  features
}) => {
  return (
    <div className="feature-list">
      {features.map(({name, description, link, image}) => (
        <div className="feature-detail" key={link}>
          <img className="background-image" src={image}/>
          <Link to={link}>
            <div className="name">
              <span>{name}</span>
            </div>
            <div className="description">
              <span>{description}</span>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default FeatureDetail