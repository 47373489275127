import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { getProfile } from '../../../actions/UserAction';
import { dateInfo } from '../../../helper/date';

const Profile = () => {
  const [user, setUser] = useState({})
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    const init = async () => {
      let data = await getProfile()
      if (data !== false)
        setUser(data)
    }
    init()
  }, [])

  return (
    <Container>
      <h1 className="titre-section">Mon compte</h1>
      <hr/>
      <h3 className="titre-section">Informations Personnelles</h3>
      <Row>
        <Form.Group as={Col} className="info-profil-verouille" controlId="name">
          <Form.Label>Pseudonyme</Form.Label>
          <Form.Control
            required
            type="text"
            disabled={true}
            value={user.name}
            placeholder="Pseudonyme"
          />
        </Form.Group>
        <Form.Group as={Col} className="info-profil-verouille" controlId="creation">
          <Form.Label>Date de création</Form.Label>
          <Form.Control
            required
            type="text"
            disabled={true}
            value={dateInfo(user.created_at)}
            title={user.created_at}
            placeholder="Pseudonyme"
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="info-profil-verouille" controlId="role">
          <Form.Label>Rôle</Form.Label>
          <Form.Control
            required
            type="text"
            disabled={true}
            value={user.role}
            placeholder="Rôle"
            />
          </Form.Group>
      </Row>
      <h3 className="titre-section">Changer les Informations</h3>
      <Row>
        <Form.Group as={Col} className="champ-formulaire" controlId="name" md={8}>
          <Form.Label>Avatar</Form.Label>
          <Form.Control
            required
            type="file"
            onChange={e => setImageUrl(URL.createObjectURL(e.target.files[0]))}
            defaultValue={user.avatar}
            placeholder="Avatar"
          />
        </Form.Group>
        <Col className="show-avatar">
          {imageUrl &&<img className="avatar-compte" alt="Votre avatar de compte" src={imageUrl}/>}
        </Col>
      </Row>
      <Row>
        <Form.Group as={Col} className="champ-formulaire" controlId="name">
          <Form.Label>Adresse mail</Form.Label>
          <Form.Control
            required
            type="email"
            defaultValue={user.email}
            placeholder="Adresse mail"
          />
        </Form.Group>
        <Form.Group as={Col} className="champ-formulaire" controlId="phone">
          <Form.Label>Téléphone</Form.Label>
          <Form.Control
            type="text"
            value={user.phone}
            placeholder="Téléphone"
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="champ-formulaire" controlId="city">
          <Form.Label>Ville</Form.Label>
          <Form.Control
            type="text"
            value={user.city}
            placeholder="Ville"
        />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="champ-formulaire" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            value={user.description}
            placeholder="Description de votre profil"
        />
        </Form.Group>
      </Row>
    </Container>
  );
}

export default Profile;
