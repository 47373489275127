import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import yup from 'yup'
import { useState } from 'react';
import ErrorHandler from '../../form/ErrorHandler';

const Register = ({doRegister}) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirm] = useState('')
  const [error, setError] = useState({})

  const handleSubmit = async e => {
    e.preventDefault()
    let response = await doRegister({name, email, password, confirmPassword})
    if (typeof response === "object")
      setError(response)
  }
  return (
    <Col sm={6} xs={12}>
      <h2 className="titre-formulaire">Inscription</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="champ-formulaire">
          <Form.Group as={Col} controlId="name">
            <Form.Label>Pseudonyme</Form.Label>
            <Form.Control
              required
              type="text"
              className={error.name ? 'error' : ''}
              onChange={e => setName(e.target.value)}
              placeholder="Pseudonyme..."
            />
            <ErrorHandler setter={setError} getter={error} subName={"name"}/>
          </Form.Group>
        </Row>
        <Row className="champ-formulaire">
          <Form.Group as={Col} controlId="email">
            <Form.Label>Adresse mail</Form.Label>
            <Form.Control
              required
              onChange={e => setEmail(e.target.value)}
              type="email"
              className={error.email ? 'error' : ''}
              placeholder="Adresse mail..."
            />
            <ErrorHandler setter={setError} getter={error} subName={"email"}/>
          </Form.Group>
        </Row>
        <Row className="champ-formulaire">
          <Form.Group as={Col} controlId="password">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              required
              type="password"
              className={error.password ? 'error' : ''}
              onChange={e => setPassword(e.target.value)}
              placeholder="Mot de passe..."
            />
            <ErrorHandler setter={setError} getter={error} subName={"password"}/>
          </Form.Group>
        </Row>
        <Row className="champ-formulaire">
          <Form.Group as={Col} controlId="confirmPassword">
            <Form.Label>Confirmation du mot de passe</Form.Label>
            <Form.Control
              required
              onChange={e => setConfirm(e.target.value)}
              className={error.confirmPassword ? 'error' : ''}
              type="password"
              placeholder="Confirmation du mot de passe..."
            />
            <ErrorHandler setter={setError} getter={error} subName={"confirmPassword"}/>
          </Form.Group>
        </Row>
        <Row className="champ-formulaire">
          <Button className="submit-form" variant="primary" type="submit">
            S'inscrire
          </Button>
        </Row>
      </Form>
    </Col>
  )
}

export default Register