import { useEffect, useRef, useState } from 'react';

import './styles.scss';
import { capitalize } from '../../helper/string';
import { dateInfo } from '../../helper/date'
import { getMessages, sendMessage ,initChat, newMessageHandler } from '../../actions/ChatAction'
import Play from '../../images/icons/arrow.svg'

const Chat = () => {
  const [name, setName] = useState("")
  const [newMessage, setNewMessage] = useState("")
  const MessageContainer = useRef()
  const [fieldValue, setFieldValue] = useState("")
  const [isLoading, toggleLoading] = useState(true)
  const [wasSending, toggleSending] = useState(false)
  const [limitMessages, setLimit] = useState(10)
  const [messages, setMessages] = useState([])
  useEffect(() => {
    if (localStorage.getItem('chat_name'))
      setName(localStorage.getItem('chat_name'))
    initChat()
    newMessageHandler(res => {
      setNewMessage(res[0])
      if (wasSending) {
        toggleSending(false)
        MessageContainer?.current?.lastChild?.scrollIntoView()
      }
    })
    const init = async () => {
      let data = await getMessages()
      if (typeof data === "object")
        setMessages(data)
      toggleLoading(false)
    }
    init()
  }, [])
  useEffect(() => {
    if (newMessage !== "") {
      setMessages([...messages, newMessage])
      setNewMessage("")
    }
  }, [newMessage])

  useEffect(() => {
    MessageContainer?.current?.lastChild?.scrollIntoView()
  }, [MessageContainer?.current])

  const handleNameSubmit = e => {
    e.preventDefault()
    if (fieldValue && fieldValue !== "") {
      setName(fieldValue)
      setFieldValue("")
      localStorage.setItem('chat_name', fieldValue)
    }
  }
  const handleMessageSubmit = e => {
    e.preventDefault()
    if (fieldValue && fieldValue.trim() !== "") {
      sendMessage(name, fieldValue.trim())
      setFieldValue("")
      toggleSending(true)
    }
  }
  const renderHtml = (str, recursive = 0) => (
    new DOMParser()
      .parseFromString(`<!doctype html><body>${recursive === 0 ? str : renderHtml(str, recursive - 1)}`, 'text/html')
      .body.textContent
  )
 
  return (
    <div className="chat-div">
      {!name && <form className="name-form" onSubmit={handleNameSubmit}>
        <input type="text" 
          value={fieldValue} 
          onChange={e => {
            if (e.target.value.trim().length !== 21)
              setFieldValue(capitalize(e.target.value.trim()))
          }} 
          placeholder="Votre nom"></input>
        <input type="submit" value="Commencer"/>
      </form>}
      {name && <div className="message-container">
        <div ref={MessageContainer}>
          {messages.slice(limitMessages * -1).length !== messages.length && <div className="see-more" onClick={() => setLimit(limitMessages + 10)}>
            Voir plus de messages
          </div>}
          <div className="back-to-start" onClick={() => MessageContainer?.current?.lastChild?.scrollIntoView()}>
            <img src={Play}/>
          </div>
          {messages.slice(limitMessages * -1).map((message, index) => (
            <div key={index} className="message-dialog">
              <img src={`https://avatars.dicebear.com/api/bottts/${message.token_ip}.svg`}/>
              <div className="content">
                {renderHtml(message.content)}
                <div className="detail">{dateInfo(message.created_at)} de {renderHtml(message.name, 1)}  
                {' '}(#<span className="token_ip">{message.token_ip.slice(0, 10)+ message.token_ip.slice(-10)}</span>)
                </div>
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={handleMessageSubmit}>
          <input value={fieldValue} type="text" onChange={e => {
            if (e.target.value.trim().length !== 301)
              setFieldValue(e.target.value)
          }} placeholder="Message..."></input>
          <input type="submit"/>
        </form>
      </div>}
    </div>
  )
}

export default Chat