import Row from 'react-bootstrap/Row';
import {useEffect} from "react"

import { useNavigate } from "react-router-dom"
import { doLogin, doRegister, getProfile } from '../../../actions/UserAction'

import Register from './Register';
import Login from './Login';

const LoginRegister = ({showLogin, showRegister}) => {
  const navigate = useNavigate()
  useEffect(() => {
    const init = async() => {
      let data = await getProfile()
      if (data !== false)
        navigate('/profil')
    }
    init()
  }, [navigate])
  return (
  <>
    <Row>
      {(showLogin ?? true) && <Login doLogin={doLogin} />}
      {(showRegister ?? true) && <Register doRegister={doRegister} />}
    </Row>
  </>
  )
}

export default LoginRegister;