import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel'

import yup from 'yup';
import { useState } from 'react';
import { useNavigate } from "react-router-dom"

import ErrorHandler from '../../form/ErrorHandler';

const Login = ({doLogin}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const navigate = useNavigate()

  const handleSubmit = async e => {
    e.preventDefault()
    let response = await doLogin({email, password})
    if (typeof response === "string")
      setError(response)
    else if (response)
      navigate('/profil')
  }
  return (
    <Col sm={6} xs={12}>
      <h2 className="titre-formulaire">Connexion</h2>
      <ErrorHandler setter={setError} getter={error}/>
      {error && <div onClick={() => setError(null)} className="general-error">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Row className="champ-formulaire">
          <Form.Group as={Col} >
            <Form.Label controlId="connexion-email">Adresse mail</Form.Label>
              <Form.Control
                required
                type="email"
                className={error ? 'error' : ''}
                for="connexion-email"
                onChange={e => setEmail(e.target.value)}
                placeholder="Adresse mail..."
              />
          </Form.Group>
        </Row>
        <Row className="champ-formulaire">
          <Form.Group as={Col} controlId="password">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              required
              type="password"
              className={error ? 'error' : ''}
              onChange={e => setPassword(e.target.value)}
              placeholder="Mot de passe..."
            />
          </Form.Group>
        </Row>
        <Row className="champ-formulaire">
          <Button className="submit-form" variant="primary" type="submit">
            Se connecter
          </Button>
        </Row>
      </Form>
    </Col>
  )
}

export default Login