const ErrorHandler = ({
  setter,
  getter,
  subName
}) => {
  if (typeof subName === "string") {
    return (<>
      {getter[subName] && <div 
          onClick={() => setter({[subName]: null, ...getter})} 
          className="sub-error"
        >
        {getter[subName]}
        </div>}
    </>)
  }
  return (<>
    {getter && <div 
        onClick={() => setter(null)} 
        className="general-error"
      >
      {getter}
      </div>}
  </>)
}

export default ErrorHandler;