import './styles.scss'

const Loader = () => {
  return (
    <div className="loader">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="53.11px"
        height="46.2px" viewBox="0 0 53.11 46.2">
      <defs>
      </defs>
      <g>
        <g>
          <path className="st0" d="M46.7,22.85c-0.01,1.37-0.14,2.74-0.38,4.1c-0.1,0.53-0.6,0.88-1.13,0.78c-0.52-0.1-0.87-0.59-0.78-1.12
            c0.21-1.24,0.31-2.5,0.3-3.76c0-0.55,0.44-1,0.99-1.01c0.55,0,1,0.44,1.01,0.99C46.7,22.84,46.7,22.85,46.7,22.85z"/>
          <path className="st0" d="M45.23,30.91c-0.48,1.28-1.07,2.53-1.76,3.71c-0.26,0.44-0.82,0.58-1.25,0.33c-0.43-0.25-0.58-0.81-0.33-1.24
            c0.62-1.1,1.15-2.25,1.57-3.43c0.17-0.49,0.72-0.75,1.21-0.57c0.49,0.17,0.75,0.72,0.57,1.21C45.23,30.9,45.23,30.91,45.23,30.91z
            "/>
          <path className="st0" d="M41.09,37.95c-0.89,1.04-1.86,2-2.91,2.87c-0.37,0.3-0.91,0.25-1.21-0.11c-0.3-0.36-0.25-0.9,0.1-1.2
            c0.96-0.82,1.85-1.72,2.66-2.7c0.31-0.38,0.87-0.43,1.25-0.12s0.43,0.87,0.12,1.25C41.1,37.94,41.1,37.95,41.09,37.95z"/>
          <path className="st0" d="M34.82,43.15c-1.18,0.67-2.43,1.25-3.71,1.7c-0.42,0.15-0.88-0.07-1.03-0.49c-0.15-0.42,0.07-0.87,0.48-1.03
            c1.19-0.44,2.33-0.99,3.43-1.63c0.4-0.23,0.91-0.1,1.14,0.3c0.23,0.4,0.1,0.91-0.3,1.14C34.83,43.14,34.83,43.14,34.82,43.15z"/>
          <path className="st0" d="M27.17,45.88c-1.34,0.23-2.7,0.33-4.06,0.33c-0.41,0-0.75-0.34-0.75-0.75c0-0.41,0.34-0.74,0.74-0.75
            c1.27-0.01,2.54-0.13,3.79-0.36c0.42-0.08,0.83,0.2,0.91,0.63s-0.2,0.83-0.63,0.91C27.18,45.88,27.17,45.88,27.17,45.88z"/>
          <path className="st0" d="M19.06,45.82c-1.34-0.24-2.65-0.6-3.92-1.08c-0.36-0.13-0.54-0.53-0.41-0.89c0.13-0.36,0.53-0.54,0.88-0.41
            c1.2,0.43,2.44,0.75,3.69,0.96c0.39,0.07,0.66,0.44,0.59,0.83c-0.07,0.39-0.44,0.66-0.83,0.59
            C19.07,45.83,19.06,45.83,19.06,45.82z"/>
          <path className="st0" d="M11.47,43.01c-1.17-0.69-2.27-1.47-3.3-2.35c-0.27-0.23-0.3-0.63-0.07-0.9c0.23-0.27,0.63-0.3,0.89-0.08
            c0.98,0.81,2.04,1.54,3.15,2.17c0.32,0.18,0.43,0.59,0.25,0.91C12.21,43.08,11.8,43.19,11.47,43.01
            C11.48,43.01,11.47,43.01,11.47,43.01z"/>
          <path className="st0" d="M5.33,37.78c-0.86-1.04-1.63-2.15-2.3-3.32c-0.16-0.28-0.06-0.64,0.22-0.8c0.28-0.16,0.63-0.06,0.79,0.21
            c0.65,1.1,1.4,2.15,2.23,3.12c0.22,0.26,0.19,0.64-0.07,0.86C5.94,38.07,5.55,38.04,5.33,37.78C5.33,37.79,5.33,37.78,5.33,37.78z
            "/>
          <path className="st0" d="M1.34,30.79c-0.45-1.27-0.79-2.58-1.02-3.9c-0.05-0.29,0.15-0.56,0.43-0.61c0.28-0.05,0.55,0.14,0.61,0.43
            c0.23,1.26,0.57,2.5,1.02,3.71c0.11,0.29-0.04,0.61-0.33,0.71S1.45,31.08,1.34,30.79C1.34,30.79,1.34,30.79,1.34,30.79z"/>
          <path className="st0" d="M0,22.87c0.01-1.34,0.13-2.69,0.37-4.01c0.05-0.26,0.29-0.43,0.55-0.38c0.25,0.05,0.42,0.29,0.38,0.54
            c-0.21,1.27-0.31,2.56-0.3,3.85c0,0.28-0.22,0.5-0.5,0.5C0.23,23.37,0,23.15,0,22.87C0,22.88,0,22.87,0,22.87z"/>
          <path className="st0" d="M1.43,14.98c0.47-1.26,1.04-2.47,1.72-3.63c0.12-0.2,0.37-0.26,0.57-0.15c0.2,0.12,0.26,0.37,0.15,0.57
            c-0.64,1.12-1.18,2.3-1.61,3.51c-0.08,0.23-0.34,0.35-0.57,0.27C1.47,15.47,1.35,15.22,1.43,14.98
            C1.43,14.99,1.43,14.98,1.43,14.98z"/>
          <path className="st0" d="M5.48,8.09C6.34,7.07,7.3,6.13,8.33,5.27c0.15-0.13,0.38-0.11,0.51,0.05C8.96,5.47,8.94,5.7,8.79,5.83
            C7.81,6.67,6.9,7.59,6.07,8.59C5.94,8.75,5.69,8.78,5.53,8.64C5.36,8.5,5.34,8.26,5.48,8.09C5.47,8.09,5.48,8.09,5.48,8.09z"/>
          <path className="st0" d="M11.61,3c1.16-0.66,2.37-1.22,3.63-1.67c0.16-0.06,0.33,0.03,0.39,0.18c0.06,0.16-0.02,0.33-0.18,0.39
            c-1.21,0.45-2.39,1.01-3.5,1.67c-0.16,0.09-0.36,0.04-0.46-0.12C11.4,3.3,11.45,3.09,11.61,3C11.61,3,11.61,3,11.61,3z"/>
          <path className="st0" d="M19.1,0.32C20.41,0.1,21.74-0.01,23.07,0c0.14,0,0.25,0.11,0.25,0.25c0,0.14-0.11,0.25-0.25,0.25
            c-1.3,0.01-2.6,0.13-3.88,0.37c-0.15,0.03-0.3-0.07-0.32-0.22S18.95,0.35,19.1,0.32C19.1,0.32,19.1,0.32,19.1,0.32z"/>
          <path className="st0" d="M27.04,0.37c1.31,0.24,2.59,0.59,3.84,1.05c0.1,0.04,0.15,0.15,0.11,0.25c-0.04,0.1-0.15,0.15-0.25,0.12
            c-1.23-0.44-2.5-0.76-3.78-0.98c-0.12-0.02-0.2-0.13-0.18-0.26C26.8,0.43,26.92,0.35,27.04,0.37C27.04,0.37,27.04,0.37,27.04,0.37
            z"/>
          <path className="st0" d="M34.46,3.12c1.14,0.67,2.23,1.44,3.23,2.3c0.06,0.05,0.07,0.14,0.02,0.2c-0.05,0.06-0.14,0.07-0.19,0.02
            c-1.01-0.83-2.09-1.58-3.22-2.22c-0.08-0.05-0.11-0.15-0.06-0.23C34.28,3.1,34.38,3.07,34.46,3.12
            C34.46,3.12,34.46,3.12,34.46,3.12z"/>
          <path className="st0" d="M40.48,8.23c0.84,1.02,1.6,2.1,2.25,3.25c0.02,0.04,0.01,0.09-0.03,0.11c-0.04,0.02-0.09,0.01-0.11-0.03
            c-0.67-1.13-1.43-2.2-2.28-3.19c-0.04-0.05-0.03-0.12,0.01-0.16C40.37,8.18,40.44,8.18,40.48,8.23
            C40.48,8.23,40.48,8.23,40.48,8.23z"/>
          <path className="st0" d="M44.38,15.07c0.44,1.24,0.78,2.52,1,3.82c0,0.02-0.01,0.03-0.02,0.03c-0.02,0-0.03-0.01-0.03-0.02
            c-0.24-1.29-0.59-2.56-1.05-3.79c-0.01-0.03,0-0.06,0.03-0.07S44.37,15.04,44.38,15.07C44.38,15.07,44.38,15.07,44.38,15.07z"/>
          <g>
            <polygon className="st0" points="38.3,28.47 45.7,25.32 53.11,28.47 45.7,10.91 			"/>
          </g>
        </g>
      </g>
      </svg>
    </div>
  )
}

export default Loader