import axios from "axios"

export const testAPI = async () => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_API}`)
    if (data)
      return true
    return false
  }
  catch (e) {
    return false;
  }
}

export const getDay = async () => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_API}/day`)
    if (data)
      return data?.celebrations
    return false
  }
  catch (e) {
    return false;
  }
}