import './styles.scss';
import contentTerms from '../../data/terms';

const Terms = () => {
  return (
    <>
      <h1 className='terms-title'>Mentions légales</h1>
      <pre className='terms-content'>{contentTerms}</pre>
    </>
  )
}

export default Terms