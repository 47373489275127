import '../svg.scss'

const ContactSVG = ({type}) => {
  return (
    <svg id="email" data-name="email" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.11 87.1">
      <polygon className="email-cls-1" points="81.05 50.72 86.61 45.16 86.61 62.25 81.05 67.81 81.05 50.72"/>
      <path className="email-cls-2" d="M150.51,2130.85v15.67l-4.56,4.56V2135.4l4.56-4.55m1-2.42L145,2135v18.5l6.56-6.56v-18.5Z" transform="translate(-64.4 -2084.47)"/>
      <polygon className="email-cls-1" points="57.23 86.6 51.67 81.05 68.77 81.05 74.32 86.6 57.23 86.6"/>
      <path className="email-cls-2" d="M133,2166l4.55,4.55H121.83l-4.55-4.55H133m.41-1H114.87l6.55,6.55h18.51l-6.55-6.55Z" transform="translate(-64.4 -2084.47)"/>
      <polygon className="email-cls-1" points="50.68 6.04 45.14 0.5 62.23 0.5 67.77 6.04 50.68 6.04"/>
      <path className="email-cls-2" d="M126.42,2085.47,131,2090H115.29l-4.54-4.55h15.67m.42-1H108.33l6.55,6.55h18.5l-6.54-6.55Z" transform="translate(-64.4 -2084.47)"/>
      <polygon className="email-cls-1" points="81.05 18.33 86.61 12.78 86.61 29.86 81.05 35.42 81.05 18.33"/>
      <path className="email-cls-2" d="M150.51,2098.46v15.67l-4.56,4.56V2103l4.56-4.55m1-2.42L145,2102.6v18.5l6.56-6.55V2096Z" transform="translate(-64.4 -2084.47)"/>
      <polygon className="email-cls-1" points="0.5 24.88 6.05 19.33 6.05 36.42 0.5 41.97 0.5 24.88"/>
      <path className="email-cls-2" d="M70,2105v15.68l-4.55,4.54v-15.67L70,2105m1-2.41-6.55,6.55v18.5L71,2121.1v-18.5Z" transform="translate(-64.4 -2084.47)"/>
      <polygon className="email-cls-1" points="18.3 6.04 12.75 0.5 29.84 0.5 35.39 6.04 18.3 6.04"/>
      <path className="email-cls-2" d="M94,2085.47l4.54,4.55H82.91l-4.55-4.55H94m.41-1H76l6.54,6.55H101l-6.54-6.55Z" transform="translate(-64.4 -2084.47)"/>
      <polygon className="email-cls-1" points="0.5 57.27 6.06 51.72 6.05 68.81 0.5 74.36 0.5 57.27"/>
      <path className="email-cls-2" d="M70,2137.4v15.68l-4.55,4.54V2142L70,2137.4m1-2.42-6.56,6.56V2160l6.55-6.55V2135Z" transform="translate(-64.4 -2084.47)"/>
      <polygon className="email-cls-1" points="24.85 86.6 19.3 81.05 36.39 81.05 41.94 86.6 24.85 86.6"/>
      <path className="email-cls-2" d="M100.58,2166l4.55,4.55H89.46L84.91,2166h15.67m.41-1H82.49l6.55,6.55h18.5L101,2165Z" transform="translate(-64.4 -2084.47)"/>
      {type === "email" && (<text className="email-cls-3" transform="translate(11.56 58.96)">@</text>)}
      {type === "phone" && (<g className="email-cls-3" transform="translate(15.56 13.96)"><path d="M53.364,40.908c-2.008-3.796-8.981-7.912-9.288-8.092c-0.896-0.51-1.831-0.78-2.706-0.78c-1.301,0-2.366,0.596-3.011,1.68   c-1.02,1.22-2.285,2.646-2.592,2.867c-2.376,1.612-4.236,1.429-6.294-0.629L17.987,24.467c-2.045-2.045-2.233-3.928-0.632-6.291   c0.224-0.309,1.65-1.575,2.87-2.596c0.778-0.463,1.312-1.151,1.546-1.995c0.311-1.123,0.082-2.444-0.652-3.731   c-0.173-0.296-4.291-7.27-8.085-9.277c-0.708-0.375-1.506-0.573-2.306-0.573c-1.318,0-2.558,0.514-3.49,1.445L4.7,3.986   c-4.014,4.013-5.467,8.562-4.321,13.52c0.956,4.132,3.742,8.529,8.282,13.068l14.705,14.705c5.746,5.746,11.224,8.66,16.282,8.66   c0,0,0,0,0,0c3.721,0,7.188-1.581,10.306-4.698l2.537-2.537C54.033,45.163,54.383,42.833,53.364,40.908z"/></g>)}
    </svg>
  )
}

export default ContactSVG;