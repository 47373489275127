import { useEffect, useState } from 'react';

import './styles.scss';
import { Col, Row } from 'react-bootstrap';
import { getToolData } from '../../actions/ToolAction'
import { useNavigate, useParams } from "react-router-dom";
import Book from '../../images/book.svg'
import Loader from '../app-components/loader';

const Tools = () => {
  const { toolName } = useParams();
  const [isLoading, toggleLoading] = useState(true)
  const [tools, setTools] = useState({})
  const navigation = useNavigate()
  useEffect(() => {
    const init = async () => {
      let data = await getToolData(toolName)
      if (typeof data === "object")
        setTools(data)
      toggleLoading(false)
    }
    init()
  }, [])
  if (isLoading)
    return (<Loader/>)
  return (
    <div className="tool-div">
      <Col>
        <h1 className='title'>{toolName}</h1>
      </Col>
      <Row className='content'>
        {Object.keys(tools).map(name => (
          <Col  
            onClick={() => navigation(`/outils/${toolName}/${name}`)}
            className="background-tool"
            style={{backgroundImage: `url(${Book})`, width: 'fit-content'}}
            key={name}
          >
            <div className='progress-background' style={{width: JSON.parse(localStorage.getItem(`${toolName}-${name}`))?.position ?? 0}}></div>
            <div className="tool-item">
              <div className="name" >{tools[name]['dc:title']}</div>
              {tools[name]['dc:creator'] !== "Unknown" && (
                <div className="description" >{tools[name]['dc:creator']}</div>
              )}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Tools