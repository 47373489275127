import { useState, useEffect } from "react"
import { getTools } from "../actions/ToolAction"
import { getDay } from "../actions/MainAction"

import FeatureDetail from "./app-components/feature-detail"
import Chat from "./chat";

const Home = () => {
  const [tools, setTools] = useState([])
  const [celebrations, setCelebrations] = useState([])
  const [filteredCelebrations, setFilteredCelebrations] = useState([])
  useEffect(() => {
    const init = async () => {
      let data = await getTools()
      if (typeof data === "object")
        setTools(data)
      data = await getDay()
      if (typeof data === "object")
        setCelebrations(data)
    }
    init()
  }, [])
  useEffect(() => {
    if (celebrations?.length !== 0)
      setFilteredCelebrations(celebrations?.sort((c, d) => c.rank_num > d.rank_num && c.rank === "férié").filter(c => c.rank !== "férié"))
  }, [celebrations])
  return (
    <>
      {filteredCelebrations?.length !== 0 && <div className="celebration-bar">
        {filteredCelebrations.map((celebration, index) =>
          (<div className="celebration-item" key={index}>
            <div className="celebration-background" style={{backgroundColor: celebration.colour}}/>
            <div className="celebration-name">{`${celebration?.title} - `}<span className="rank">{celebration?.rank}</span>
          </div></div>)
        )}
      </div>}
      {filteredCelebrations?.length === 0 && (<div className="celebration-bar empty">Jour Ordinaire</div>)}
      <Chat/>
      <FeatureDetail features={tools.map(({
        id,
        image,
        ...props
      }) => ({link: "/outils/"+id, image: `${process.env.REACT_APP_API}${image}`, ...props }))}/>
    </>
  )
}

export default Home