import './Scritracius.scss';
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container'
import Header from './partial/Header'
import Footer from './partial/Footer'
import Maintenance from './partial/maintenance';

import { testAPI } from './actions/MainAction';

import LoginRegister from './components/user/LoginRegister/index.js'
import Home from './components/Home'
import Tools from './components/tools'
import ParamTool from './components/tools/paramTool';

import Terms from './components/terms';

import { Routes, Route, useLocation } from "react-router-dom";
import Profile from './components/user/Profile';

const Scritracius = () => {
  let location = useLocation();
  const [isChecking, setChecking] = useState(true)
  const [workingState, setWorkingState] = useState(false)
  useEffect(() => {
    const init = async () => {
      let test = await testAPI()
      setWorkingState(test)
      setChecking(false)
    }
    init()
  }, [])
  if (isChecking) {
    return (
    <div className="load">
      Chargement<span className="load-animation"><span>.</span><span>.</span><span>.</span></span>
    </div>)
  }
  else if (!workingState)
    return (<Maintenance/>)
  return (
  <>
    <Header/>
    <Container className={`principal ${location.pathname.includes('mentions-legales') ? 'terms ': ''}`}>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/outils/:toolName" element={<Tools/>}/>
        <Route exact path="/outils/:toolName/:toolParam" element={<ParamTool/>}/>
        <Route exact path="mentions-legales"  element={<Terms/>}/>
        <Route exact path="connexion-inscription" element={<LoginRegister/>}/>
        <Route exact path="profil" element={<Profile/>}/>
        <Route exact path="inscription" element={<LoginRegister showLogin={false} />}/>
        <Route exact path="connexion" element={<LoginRegister showRegister={false}/>}/>
        <Route path="/*" components={Header} />
      </Routes>
    </Container>
    <Footer/>
  </>
  )
}

export default Scritracius;