import './styles.scss'

const InfoPanel = ({
  show,
  content,
  y,
  x,
}) => {
  return (
    <>
      <div className="info-panel" style={{top: `${y}px`, left: `${x}px`, opacity: show ? 1 : 0}}>
        {content}
      </div>
    </>
  )
}

export default InfoPanel