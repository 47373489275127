const contentTerms = `Le site www.scritracius.fr est édité par Grikke

Pour toute question relative à notre site internet, vous pouvez nous contacter via mon compte Discord.
Le directeur de la Publication est Grikke.

Le site est hébergé par :

OVH
2 rue Kellermann
59100, Roubaix
France

Cookies Scritracius
La navigation sur le site internet www.scritracius.fr, peut nous amener à installer sur votre ordinateur 
des « cookies ».
Un cookie est un enregistrement d’informations déposé par notre serveur dans un fichier texte situé 
sur votre l’ordinateur, informations que seul notre serveur peut consulter et modifier ultérieurement.
Il est destiné à mieux vous connaître, à mieux cerner vos centres d’intérêt pour améliorer notre service
et faciliter votre navigation sur notre site lors de vos prochaines visites.
Si votre ordinateur est configuré pour refuser les cookies (ce qui est parfaitement votre droit) 
vous pourriez avoir des difficultés à naviguer sur notre site.
Les Cookies sont utilisés pour mémoriser votre panier de commande, mémoriser vos consultations 
sur le site et à des fins statistiques.
La durée de conservation de ces informations dans votre ordinateur est de soixante (60) jours. Ces cookies 
ne nous permettent pas de vous identifier.
Vous pouvez, à tout moment, vous opposer à l’enregistrement des cookies en paramétrant 
votre navigateur de la manière suivante :
Pour Chrome : http://support.google.com
Pour Microsoft Internet Explorer : http://support.microsoft.com
Pour Mozilla Firefox : http://support.mozilla.org
Pour Safari : http://support.apple.com
Cookies de partenaires
Nous pouvons être amenés à utiliser vos données de navigation par le biais de cookies gérés par un partenaire. 
Les données utilisées sont strictement anonymes et font l’objet d’un traitement purement statistique.
Vous pourrez voir s’afficher des bannières personnalisées vous proposant des produits similaires 
ou complémentaires à ceux déjà consultés et disponibles sur le site du Scritracius. 
Si vous ne souhaitez plus voir ce type de bannières apparaître et/ou obtenir davantage d’informations sur ce procédé, 
il suffit de vous rendre aux adresses suivantes : Google : http://www.google.fr/privacy_ads.html

PROPRIÉTÉ INTELLECTUELLE
Tous les textes, illustrations, photographies, animations de notre site internet (ci-après les « Contenus protégés ») 
sont protégés par des droits de propriété intellectuelle.
Toute reproduction, totale ou partielle, des Contenus protégés, notamment par utilisation des textes, extraits 
ou représentations graphiques, sur quelque support que ce soit,
est interdite conformément aux dispositions du Code de la propriété intellectuelle.
Toutes les photographies du site n’ont qu’une valeur illustrative non contractuelle.
GESTION DES DONNÉES PERSONNELLES
L’Utilisateur est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 
pour la confiance dans l’Économie Numérique, 
la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données
(RGPD : n° 2016-679).
Finalité des données collectées
Le responsable du traitement des Données Personnelles est susceptible de traiter tout ou partie des données :
pour permettre la navigation sur le Site et la gestion et la traçabilité des prestations et services fournis à l’utilisateur 
données de connexion et d’utilisation du Site, signature de pétition, requête via le formulaire de contact, etc.
pour prévenir et lutter contre la fraude informatique (spamming, hacking…) : 
matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé)
pour améliorer la navigation sur le Site : données de connexion et d’utilisation
pour mener des enquêtes de satisfaction facultatives sur le site : adresse email
pour mener des campagnes de communication (sms, mail, courrier) : nom, numéro de téléphone, adresse email, adresse postale
Le Scritracius ne commercialise pas vos données personnelles.
Droit d’accès, de rectification et d’opposition
Conformément à la réglementation européenne en vigueur, les Utilisateurs du site disposent des droits suivants :
droit d’accès (article 15 RGPD) et de rectification (article 16 RGPD), 
de mise à jour, de complétude des données des Utilisateurs 
droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), 
lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l’utilisation, 
la communication ou la conservation est interdite
droit de retirer à tout moment un consentement (article 13-2c RGPD)
droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD)
droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)
droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet 
de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)
droit de définir le sort des données des Utilisateurs après leur mort et de choisir 
à qui le responsable du traitement des Données Personnelles devra communiquer (ou non) ses données 
à un tiers qu’il aura préalablement désigné
Si l’Utilisateur souhaite savoir comment le responsable du traitement des Données Personnelles utilise 
ses Données Personnelles, demande à les rectifier ou s’oppose à leur traitement, l’Utilisateur 
peut utiliser le contact présent en pied de page.`

export default contentTerms 