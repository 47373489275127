import './styles.scss';

const Maintenance = () => {
  return (
  <div className="maintenance">
    <div className="vert">
      <div className="logo-container">
        <p>Revenez plus tard</p>
        <p>Maintenance serveur</p>
      </div>
      <h1>Serveur en maintenance</h1>
      <p>Le site ne suit pas l'exemple de son logo et ne tourne plus. En l'attente de nouvelles instructions, tournez à sa place.</p>
    </div>
  </div>
  )
}

export default Maintenance;