import axios from "axios"

export const getTools = async () => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_API}/tool`)
    if (data)
      return data
    return false
  }
  catch (e) {
    return e;
  }
}

export const getToolData = async id => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_API}/tool/${id}`)
    if (data)
      return data.response
    return false
  }
  catch (e) {
    return e;
  }
}

export const getToolParam = async (id, param) => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_API}/tool/${id}/${param}`, {
      maxContentLength: 20,
      maxBodyLength: 20
    })
    if (data)
      return data.response
    return false
  }
  catch (e) {
    return e;
  }
}