import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom";

import contact from '../data/social'

import ContactSVG from '../images/social/contactsvg'
import DiscordContact from '../images/social/discordsvg'

const Footer = () => {
  return (
  <footer className="pied-de-page">
    <Row md={10}>
      <Col className="social-icon">
        <a href={`mailto:${contact.email}`} title="Mon adresse mail">
          <ContactSVG type="email"/>
        </a>
      </Col>
      <Col className="social-icon">
        <a href={`tel:${contact.phone}`} title="Mon adresse mail">
          <ContactSVG type="phone"/>
        </a>
      </Col>
      <Col className="social-icon">
        <a href={contact.discord} title="Discord">
          <DiscordContact/>
        </a>
      </Col>
      <Col className="terms-conditions" md={8}>
        <Link to='/mentions-legales'>
          Mentions légales
        </Link>
      </Col>
    </Row>
  </footer>
  )
}

export default Footer;