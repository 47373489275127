import { useEffect, useState } from 'react';

import './styles.scss';
import { Col, Row } from 'react-bootstrap';
import { getToolParam, getToolData } from '../../../actions/ToolAction'
import { useNavigate, useParams } from "react-router-dom";
import {Helmet} from "react-helmet";

import Loader from '../../app-components/loader';
import InfoPanel from "../../app-components/info-panel"
import DropdownMenu from '../../app-components/dropdown-menu';

const ParamTool = () => {
  const { toolName, toolParam } = useParams();
  const [param, setParam] = useState({})
  const [content, setContent] = useState([])
  const [isLoading, toggleLoading] = useState(true)
  const [active, setActive] = useState(JSON.parse(localStorage.getItem(`${toolName}-${toolParam}`))?.active ?? 0)

  const [showInfo, toggleInfo] = useState(false)
  const [contentPanel, setContentPanel] = useState(0)
  const [getX, setX] = useState(0)
  const [getY, setY] = useState(0)

  const [searchResult, setResult] = useState([])

  const isValid = page => typeof page !== "object" && page.trim() !== ""
  const handleSearch = e => {
    if (e.target.value.trim().length !== 0) {
      let arraySearch = []
      let regex = new RegExp(e.target.value.trim().replaceAll("'",'’'), "gi")
      content.every((page, index) => {
        let matchTest = [...page.matchAll(regex)]
        if (Array.isArray(matchTest))
          arraySearch = [...arraySearch,...matchTest.map(({...props}) => ({...props, page:index}))]
        if (arraySearch.length >= 10)
          return false
        return true
      })
      return setResult(arraySearch)
    }
    setResult([])
  }
  const renderSearch = ({index, searchValue, input}) => {
    let filter = [input.slice(0, index), input.slice(index, index + searchValue.length), input.slice(index + searchValue.length)]
    let first = filter[0].slice(-30).replaceAll('-\n', '').replaceAll('\n', ' ')
    let last = filter[2].slice(0, 30).replaceAll('-\n', '').replaceAll('\n', ' ')
    return (<>
      {first !== filter[0] && "..."} {first}
      <span style={{fontWeight: "bolder"}}>{filter[1]}</span>
      {last} {last !== filter[2] && "..."}
    </>)
  }
  const renderPage = page => (<div className="line-container">
    {page.split('\n').map((p, index) => (
      <div className="line" style={{fontWeight: p.toUpperCase() === p ? 'bold' : ''}} key={index}>
        {p}
      </div>
    ))}
    <div id="last-line"></div>
  </div>)
  useEffect(() => {
    const init = async () => {
      let data = await getToolData(toolName)
      if (typeof data === "object")
        setParam(data[toolParam])
      data = await getToolParam(toolName, toolParam)
      if (typeof data === "object")
        setContent(data?.filter(isValid))
      toggleLoading(false)
    }
    init()
  }, [])
  const handleMouseMove = e => {
    let position = e.pageX - e.target.getBoundingClientRect().left
    toggleInfo(true)
    setX(position)
    setY(e.target.offsetTop - 20)
    setContentPanel(`Page ${active +1} / ${content.length}`)
  }
  useEffect(() => {
    let position = `${(active + 1) / content.length * 100}%`
    if (position !== "NaN%")
      localStorage.setItem(`${toolName}-${toolParam}`, JSON.stringify({position, active}))
  }, [active])
  if (isLoading)
    return (<Loader/>)
  return (
    <div className="param-div">
      <Helmet>
        <title>Scritracius - {param['dc:title']}</title>
        <meta name="description" content={content[0].slice(0, 200)} />
        <meta name="og:description" content={content[0].slice(0, 200)} />
        <meta name="og:title" content={param['dc:title']} />
      </Helmet>
      <Col>
        <h1 className='title'>{param['dc:title']}</h1>
        <DropdownMenu 
          searchHandler={handleSearch} 
          data={searchResult.map(({page, index, ["0"]: searchValue, input})=> ({
            title: `Page ${page+1} sur ${content.length}`, 
            onClick: () => setActive(page),
            description: renderSearch({searchValue, index, input})
          }))}
        />
      </Col>
      <Row className='content'>
        <InfoPanel show={showInfo} content={contentPanel} y={getY} x={getX}/>
        <div className="read-bar" id="first-line" onMouseMove={handleMouseMove} onMouseLeave={() => toggleInfo(false)}>
          <div className="progress" style={{width: `${(active + 1) / content.length * 100}%`}}></div>
        </div>
        <div className="page">
          <Row className="param-navigator">
            <a href="#last-line" className={`${!content[active - 1] ? "disabled" : ""}`} onClick={() => setActive(active - 1)}>{'<'}</a>
            <a href="#first-line" className={`${!content[active + 1] ? "disabled" : ""}`} onClick={() => setActive(active + 1)}>{'>'}</a>
          </Row>
          {content?.[active] && renderPage(content[active])}
        </div>
      </Row>
    </div>
  )
}

export default ParamTool