import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom";

import { useEffect, useState } from "react"
import { getProfile } from '../actions/UserAction'

import Logo from '../images/logo.png'

const Header = () => {
  const [logined, setLogin] = useState(false)
  useEffect(() => {
    const init = async() => {
      let data = await getProfile()
      if (data !== false)
        setLogin(true)
    }
    init()
  }, [])
  return (
  <Container className="entete" fluid>
    <Row md={10}>
      <div className="onglet-mobile">
        <Link to='/'>
          {"<"}
        </Link>
      </div>
      <Col className="onglet-icone">
        <Link to='/'>
          <img src={Logo} className="scritracius-logo" alt="Logo du Scritracius" />
        </Link>
      </Col>
      <div className="onglet-mobile">
        <Link to='/outils'>
          X
        </Link>
      </div>
    </Row>
  </Container>
  )
}

export default Header;