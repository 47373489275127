import React, {useRef, useState, useEffect} from 'react';
import './styles.scss'

const DropdownMenu = ({searchHandler, data}) => {
  const [showDropdown, toggleDropdown] = useState(false)
  const InputRef = useRef()
  const ContainerRef = useRef()

  const handleClickOutside = (event) => {
    if (ContainerRef.current && !ContainerRef.current.contains(event.target))
      toggleDropdown(false)
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, !showDropdown);
    return () => {
      document.removeEventListener(
        "click",
        handleClickOutside,
        !showDropdown
      );
    };
  }, [])
  return (
    <div className="dropdown-container" ref={ContainerRef}>
      <div className="search-text">
        <input type="text" ref={InputRef} onChange={(e) => {
          toggleDropdown(true)
          searchHandler(e)
        }} onFocus={() => toggleDropdown(true)} placeholder={"Rechercher..."} maxLength={20}/>
        <div className="close-button" onClick={() => {
          toggleDropdown(false)
          InputRef.current.value = ""
          }}>X</div>
      </div>
      {(showDropdown && data?.length !== 0) && <div className="dropdown-list" onBlur={() => toggleDropdown(false)}>
        {data.slice(0, 10).map(({title, description, onClick}, key) => (
          <div key={key} className="dropdown-item" onClick={() => {
            InputRef.current.value = ""
            toggleDropdown(false)
            onClick()
          }}>
            <div className="title">{title}</div>
            {description && <div className="description">{description}</div>}
          </div>
        ))}
      </div>}
      {(showDropdown && (data?.length === 0 && InputRef.current.value !== "")) && <div className="dropdown-list">
        <div className="dropdown-item">
          <div className="not-found">Aucun résultat</div>
        </div>
      </div>}
    </div>
  );
}

export default DropdownMenu;
