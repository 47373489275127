const padRender = (len, num) => {
  num = `${num}`
  return num.padStart(len, '0')
}

export const dateInfo = dateTime => {
  let d = new Date(dateTime)
  let date = `${padRender(2, d.getDate())}/${padRender(2, d.getMonth() + 1)}/${padRender(4, d.getFullYear())}`
  let time = `${padRender(2, d.getHours())}h${padRender(2, d.getMinutes())}`
  return `Le ${date} à ${time}`
}