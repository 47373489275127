import Cookies from "universal-cookie"
import axios from "axios"

export const doLogin = async ({email, password}) => {
  let cookie = new Cookies()
  try {
    let { data } = await axios.post(`${process.env.REACT_APP_API}/login`, {email, password})
    if (data.status) {
      cookie.set('user', data.response)
      return true
    }
    return data.errors
  }
  catch (e) {
    return e;
  }
}

export const doRegister = async ({name, email, password, confirmPassword}) => {
  try {
    let { data } = await axios.post(`${process.env.REACT_APP_API}/user`, {name, email, password, confirmPassword})
    if (data.status)
      return true
    return data.errors
  }
  catch (e) {
    return e;
  }
}

export const getProfile = async () => {
  let cookie = new Cookies()
  let user = cookie.get('user')
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_API}/profile`, {headers: {authorization: user}})
    if (data.status)
      return data.response
    return false
  }
  catch (e) {
    return e;
  }
}