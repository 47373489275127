import axios from "axios"
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_API)

export const getMessages = async () => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_API}/chat`)
    if (data.status)
      return data.response
    return data.errors
  }
  catch (e) {
    return e;
  }
}

export const sendMessage = async (name, content) => {
  try {
    let { data } = await axios.post(`${process.env.REACT_APP_API}/chat`, {name, content})
    if (data.status)
      return true
    return data.errors
  }
  catch (e) {
    return e;
  }
}

export const initChat = () => {
  socket.on("connect", () => {
    console.log('test')
  });
}

export const newMessageHandler = func => {
  socket.on('new_message', func)
}